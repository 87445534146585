@use "../../../../../base/Resources/Public/Scss/includes/core" as core;
@use "sass:string";

// @todo stefi mobile
// Auf Startseite vorhanden
// showcase-url: https://go-e.local/de-at/showcase/build/modules/goe/six-steps.html

.six-steps {
  position: relative;
  // --steps-size is set by javascript
  @include core.named-breakpoint(large) {
    aspect-ratio: 1;
    overflow: visible;
  }
  &__dots {
    @include core.named-breakpoint(small, down) {
      display: none;
    }
  }
  &__header {
    @include core.named-breakpoint(medium, down) {
      text-align: center;
      margin-bottom: var(--space-regular);
    }
    @include core.named-breakpoint(large) {
      position: absolute;
      z-index: 1;
      width: 320px;
      left: 0;
      top: 10%;
      .base-header-group__header {
        opacity: 0;
      }
      :is(.base-header-group__subheader, .base-module-links) {
        opacity: 0;
      }
    }
    .base-header-group {
      &__header {
        color: var(--color-text);
      }
      &__subheader {
        color: var(--color-header);
        margin-bottom: 0;
      }
    }
  }
  &__image {
    @include core.named-breakpoint(medium, down) {
      margin-bottom: var(--space-regular);
    }
    @include core.named-breakpoint(large) {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      overflow: hidden;
      width: string.unquote("min(100%, 35%)");
      opacity: 0;
    }
    &::after {
      @include core.named-breakpoint(large) {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
    > img {
      max-width: 100%;
      height: auto;
      @include core.named-breakpoint(large) {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-width: none;
        top: 0;
        left: 0;
      }
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    @include core.named-breakpoint(medium, down) {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
    @include core.named-breakpoint(large) {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  &__item {
    @include core.named-breakpoint(medium, down) {
      padding: var(--space-regular);
    }
    @include core.named-breakpoint(small, down) {
      padding: var(--space-regular) 0;
    }
    @include core.named-breakpoint(large) {
      width: string.unquote("min(100%, 256px)");
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      &:nth-child(1) {
        --transformX: 0;
        --transformY: calc(var(--steps-size) * 0.45);
        --animationDelay: 0s;
      }
      &:nth-child(2) {
        --transformX: calc(var(--steps-size) * 0.05);
        --transformY: calc(var(--steps-size) * 0.67);
        --animationDelay: 0.25s;
      }
      &:nth-child(3) {
        --transformX: -50%;
        --transformY: calc(var(--steps-size) * 0.78);
        --animationDelay: 0.5s;
        left: 50%;
      }
      &:nth-child(4) {
        //--transformX: calc(var(--steps-size) * 0.65);
        --transformX: calc(var(--steps-size) * -0.05);
        --transformY: calc(var(--steps-size) * 0.65);
        --animationDelay: 0.75s;
      }
      &:nth-child(5) {
        --transformX: 0;
        --transformY: calc(var(--steps-size) * 0.4);
        --animationDelay: 1s;
      }
      &:nth-child(6) {
        --transformX: calc(var(--steps-size) * 0.65);
        --transformY: calc(var(--steps-size) * 0.18);
        --animationDelay: 1.25s;
      }
      &:nth-child(4), &:nth-child(5) {
        left: auto;
        right: 0;
      }
      transform: translate(string.unquote("var(--transformX)"), string.unquote("var(--transformY)"));
    }
    @include core.named-breakpoint(xlarge) {
      &:nth-child(2) {
        --transformX: calc(var(--steps-size) * 0.1);
        --transformY: calc(var(--steps-size) * 0.7);
      }
      &:nth-child(3) {
        --transformY: calc(var(--steps-size) * 0.8);
      }
      &:nth-child(4) {
        //--transformX: calc(var(--steps-size) * 0.7);
        --transformX: calc(var(--steps-size) * -0.1);
        --transformY: calc(var(--steps-size) * 0.7);
      }
      &:nth-child(5) {
        //--transformX: calc(var(--steps-size) * 0.8);
        --transformY: calc(var(--steps-size) * 0.45);
      }
      &:nth-child(6) {
        --transformX: calc(var(--steps-size) * 0.7);
        --transformY: calc(var(--steps-size) * 0.2);
      }
      transform: translate(string.unquote("var(--transformX)"), string.unquote("var(--transformY)"));
    }
  }
  &__item-header {
    display: flex;
    flex-direction: row;
    @include core.named-breakpoint(medium, down) {
      margin: 0 auto;
      justify-content: center;
      text-align: center;
      :is(h4, .h4) {
        font-size: 1.9em;
        max-width: calc(100% - 150px);
        text-align: left;
      }
    }
    :is(h4, .h4) {
      flex: 1 1 0;
      @include core.named-breakpoint(small, down) {
        font-size: 1.4em;
        max-width: calc(100% - 90px);
      }
      @include core.named-breakpoint(large) {
        font-size: 1.5em;
      }
      @include core.named-breakpoint(xlarge) {
        font-size: 1.6rem;
      }
    }
    .six-steps__indicator {
      flex: 0 0 auto;
      margin-right: 0.6rem;
      &--without-title {
        margin-right: 0;
        > span { margin-left: 0; }
      }
      > span {
        margin-top: -5px;
        margin-bottom: 6px;
        display: block;
        line-height: 1;
        color: var(--color-back);
        font-size: 58px;
        -webkit-text-stroke: 2px var(--color-contrast);
        @include core.named-breakpoint(medium) {
          font-size: 69px;
          margin-left: -3px;
        }
        @include core.named-breakpoint(large) {
          font-size: 54px;
        }
        @include core.named-breakpoint(xlarge) { font-size: 69px; }
      }
    }
  }
  &__indicator {
    font-family: var(--font-header-family);
    font-weight: var(--font-header-bold-weight);
    color: var(--color-contrast);
    flex: 0 0 100%;
    // applies if a title is present as well
    /*&:not(:only-child) {
      flex: 0 0 91px;
      height: auto;
    }*/
  }
  &__item-body {
    text-align: center;
    @include core.named-breakpoint(large) {
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
      opacity: 0;
    }
  }

  &.start-animation {
    .six-steps {
      &__header {
        @include core.named-breakpoint(large) {
          .base-header-group__header {
            animation: 0.6s ease-in-out 0s 1 normal forwards running reveal-title;
          }
          :is(.base-header-group__subheader, .base-module-links) {
            animation: 0.8s ease-in-out 0s 1 normal forwards running reveal-pre-head;
          }
        }
      }
      &__item {
        @include core.named-breakpoint(large) {
          animation: 1s ease var(--animationDelay) 1 normal forwards running reveal-milestone;
        }
      }
      &__item-body {
        @include core.named-breakpoint(large) {
          animation: 1s ease 1.8s 1 normal forwards running reveal-description;
        }
      }
      &__image {
        @include core.named-breakpoint(medium, down) {
          text-align: center;
        }
        @include core.named-breakpoint(large) {
          animation: 2.1s ease 0s 1 normal forwards running pop-in;
        }
      }
    }
  }
  @keyframes pop-in {
    0% {
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 0;
    }
    85% {
      transform: translate(-50%, -50%) scale(1.05);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
  @keyframes reveal-milestone {
    from {
      opacity: 0;
      transform: translate(var(--transformX), var(--transformY)) scale(0);
    }
    to {
      opacity: 1;
      transform: translate(var(--transformX), var(--transformY)) scale(1);
    }
  }
  @keyframes reveal-description {
    from {
      opacity: 0;
      transform: translateY(2rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes reveal-pre-head {
    from {
      opacity: 0;
      transform: translateY(0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes reveal-title {
    from {
      opacity: 0;
      transform: translateY(1.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}