@use "functions" as fn;
@use "config" as *;
@use "sass:map" as map;
@use "sass:list" as list;
@use "sass:color" as color;

@mixin remove-margin-bottom-from-last-child {
  > *:last-child {
    margin-bottom: 0!important;
  }
}

@mixin define-color-variables($name, $value) {
  --#{$name}: #{$value};
  --#{$name}-rgb: #{fn.rgba-prefix($value)};
  --#{$name}-h: #{color.channel($value, "hue", $space: hsl)};
  --#{$name}-s: #{color.channel($value, "saturation", $space: hsl)};
  --#{$name}-l: #{color.channel($value, "lightness", $space: hsl)};
}

// @note will become obsolete once we have @scope support
// @see https://caniuse.com/css-cascade-scope
@mixin unset-color-variables($name) {
  --#{$name}: revert;
  --#{$name}-rgb: revert;
  --#{$name}-h: revert;
  --#{$name}-s: revert;
  --#{$name}-l: revert;
}

@mixin switch-color-variables($set-property, $target-property) {
  --#{$set-property}: var(--#{$target-property});
  --#{$set-property}-rgb: var(--#{$target-property}-rgb);
  --#{$set-property}-h: var(--#{$target-property}-h);
  --#{$set-property}-s: var(--#{$target-property}-s);
  --#{$set-property}-l: var(--#{$target-property}-l);
}

// what do we expect?
// setup = small: 0, medium: 640px, large: 1024px
// medium until = max-width: 639px
// medium up = min-width 640px
// medium only = min-width 640px and max-width 1023px
// medium down = max-width: 1023px
@mixin named-breakpoint($name, $direction: up) {
  $breakpoint: map.get($breakpoints, $name);
  $keys: map.keys($breakpoints);
  @if $breakpoint == null {
    @error "named breakpoint #{$name} not defined";
  }
  $query: "";
  @if $direction == until {
    @if $breakpoint > 0 {
      $query: "(max-width: #{$breakpoint - 1px})";
    }
  } @else if $direction == down {
    $index: list.index($keys, $name);
    $next-index: $index + 1;
    @each $candidate, $value in $breakpoints {
      $candidate-index: list.index($keys, $candidate);
      @if $candidate-index == $next-index {
        $query: "(max-width: #{$value - 1px})";
      }
    }
  } @else if $direction == only {
    @if $breakpoint > 0 {
      $query: "(min-width: #{$breakpoint})";
    }

    $index: list.index($keys, $name);
    $next-index: $index + 1;
    @each $candidate, $value in $breakpoints {
      $candidate-index: list.index($keys, $candidate);
      @if $candidate-index == $next-index {
        $query: "#{$query} and (max-width: #{$value - 1px})";
      }
    }
  } @else {
    @if $breakpoint > 0 {
      $query: "(min-width: #{$breakpoint})";
    }
  }
  @if $query == "" {
    @content;
  } @else {
    @media #{$query} {
      @content;
    }
  }
}

// replicated for container queries
@mixin named-container-breakpoint($name, $direction: up) {
  $breakpoint: map.get($breakpoints, $name);
  $keys: map.keys($breakpoints);
  @if $breakpoint == null {
    @error "named breakpoint #{$name} not defined";
  }
  $query: "";
  @if $direction == until {
    @if $breakpoint > 0 {
      $query: "(max-width: #{$breakpoint - 1px})";
    }
  } @else if $direction == down {
    $index: list.index($keys, $name);
    $next-index: $index + 1;
    @each $candidate, $value in $breakpoints {
      $candidate-index: list.index($keys, $candidate);
      @if $candidate-index == $next-index {
        $query: "(max-width: #{$value - 1px})";
      }
    }
  } @else if $direction == only {
    @if $breakpoint > 0 {
      $query: "(min-width: #{$breakpoint})";
    }

    $index: list.index($keys, $name);
    $next-index: $index + 1;
    @each $candidate, $value in $breakpoints {
      $candidate-index: list.index($keys, $candidate);
      @if $candidate-index == $next-index {
        $query: "#{$query} and (max-width: #{$value - 1px})";
      }
    }
  } @else {
    @if $breakpoint > 0 {
      $query: "(min-width: #{$breakpoint})";
    }
  }
  @if $query == "" {
    @content;
  } @else {
    @container #{$query} {
      @content;
    }
  }
}

// this needs media query is also rendered from ThemeConfigRenderer.php and needs to be streamlined
@mixin dark-mode() {
  @if $dark-mode-enable == true {
    $is-root: false;
    @if list.length(&) == 1 {
      $sel: list.nth(&, 1);
      @if "#{$sel}" == ':root' {
        $is-root: true;
      }
    }
    @if $is-root {
      @at-root {
        :root:not(.disable-dark-mode) {
          @media screen and (prefers-color-scheme: dark) {
            @content;
          }
        }
      }
    } @else {
      :root:not(.disable-dark-mode) & {
        @media screen and (prefers-color-scheme: dark) {
          @content;
        }
      }
    }
  }
}

// this can be used in custom themes, that will not be configured via typo3 - mostly a helper mixin for projects that will
// be migrated to pixel3 over time
// the force parameter is used because otherwise no value will exist when u use class="schema-reset flv-foo"
@mixin pixel3-flavor(
  $force: true,
  $main: $color-main,
  $contrast: $color-contrast,
  $back: $color-back,
  $text: $color-text,
  $header: $color-header,
  $link: $color-link,
  $link-hover: $color-link-hover,
  $decent-ui: $color-decent-ui,
) {
  @if $main != $color-main or $force == true {
    @include define-color-variables(color-main, $main);
  }
  @if $contrast != $color-contrast or $force == true {
    @include define-color-variables(color-contrast, $contrast);
  }
  @if $back != $color-back or $force == true {
    @include define-color-variables(color-back, $back);
  }
  @if $text != $color-text or $force == true {
    @include define-color-variables(color-text, $text);
  }
  @if $header != $color-header or $force == true {
    @include define-color-variables(color-header, $header);
  }
  @if $link != $color-link or $force == true {
    @include define-color-variables(color-link, $link);
  }
  @if $link-hover != $color-link-hover or $force == true {
    @include define-color-variables(color-link-hover, $link-hover);
  }
  @if $decent-ui != $color-decent-ui or $force == true {
    @include define-color-variables(color-decent-ui, $decent-ui);
  }
}
